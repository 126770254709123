

import {
  defineComponent, ref, Ref, PropType,
} from 'vue';
import { IBreadcrumb, UUID } from '@/domains/common';
import { IAtom, AtomType } from '@/domains/atom';
import { ITestInfoGetResponseData } from '@/services/api/lxp-multi-level-test/types';
import { IButtonsState, IAssignment } from '@/domains/assignment';
import { IRecommendation } from '@/services/api/lxp-assignment/types';

import * as MultiLevelTestService from '@/services/api/lxp-multi-level-test';
import * as LxpAssignmentService from '@/services/api/lxp-assignment';

import MultileveltestResultInfo from '@/components/multileveltest/MultileveltestResultInfo';
import TTBaseSideButton from '@/components/ui/TTBaseSideButton.vue';
import StepsButtons from '@/domains/assignment/components/StepsButtons/StepsButtons.vue';
import RecommendationSection from '@/components/recommendations/RecommendationSection';

// NOTE: заменить на MultileveltestResult + views/App/Learning/Atoms/multileveltest/MultileveltestResultView
export default defineComponent({
  name: 'MultileveltestResultView',

  components: {
    TTBaseSideButton,
    MultileveltestResultInfo,
    StepsButtons,
    RecommendationSection,
  },

  inject: ['Names'],

  props: {
    assignmentId: {
      type: Number,
      required: true,
    },

    trackSessionId: {
      type: Number,
      required: true,
    },

    track: {
      type: Object as PropType<IAtom>,
      required: true,
    },

    buttonsState: {
      type: Object as PropType<IButtonsState>,
      default: () => ({ prev: { enabled: false } }),
    },

    isShowButtons: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      test: ref({}) as Ref<ITestInfoGetResponseData>,
      isLoading: ref(true),
      recommendations: ref([]) as Ref<IRecommendation[]>,
    };
  },

  computed: {
    breadcrumbs(): IBreadcrumb[] {
      return [
        {
          text: this.$t('LearningTracks.title'),
          to: {
            name: this.Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-learning',
        },
        {
          text: this.track.name ?? '',
          to: {
            name: this.Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
            params: {
              assignmentId: String(this.assignmentId),
              trackSessionId: String(this.trackSessionId),
              playerSessionId: String(this.trackSessionId),
              atomType: AtomType.TRACK,
            },
          },
          'data-test-label': 'track-name',
        },
        {
          text: this.$t('LearningTracks.materials'),
          disabled: true,
          'data-test-label': 'track-materials',
        },
      ];
    },

    isMandatoryRecommendations(): boolean {
      return this.test?.mandatoryRecommendation ?? false;
    },

    playerSessionId(): number {
      return Number(this.$route.params.playerSessionId);
    },
  },

  watch: {
    isMandatoryRecommendations: {
      async handler(v: boolean) {
        if (v) {
          await this.getMandatoryRecommendations();
        } else {
          await this.getFreeRecommendations();
        }
      },
      immediate: true,
    },
  },

  async created() {
    await this.fetchTestInfo();
  },

  methods: {
    async fetchTestInfo() {
      this.isLoading = true;

      try {
        this.test = await MultiLevelTestService.testInfoGet({
          playerSessionId: this.playerSessionId,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    nextStep() {
      this.$emit('step:next');
      this.$emit('next-step');
    },

    previousStep() {
      this.$emit('step:prev');
      this.$emit('prev-step');
    },

    backButtonClickHandler() {
      if (this.buttonsState.prev.enabled === false) {
        this.$router.push({
          name: this.Names.R_APP_LEARNING_TRACK,
        });
        return;
      }

      this.previousStep();
    },

    async getMandatoryRecommendations() {
      try {
        const { recommendations } = await LxpAssignmentService.mandatoryRecommendationsGet({
          playerSessionId: this.playerSessionId,
        });

        this.recommendations = recommendations;
      } catch (err: any) {
        console.error(err);
        this.$di.notify.error({ content: this.$t('RecommendationSection.errorRecommendation') });
      }
    },

    async getFreeRecommendations() {
      try {
        const { recommendations } = await LxpAssignmentService.freeRecommendationsGet({
          playerSessionId: this.playerSessionId,
        });

        this.recommendations = recommendations;
      } catch (err: any) {
        console.error(err);
        this.$di.notify.error({ content: this.$t('RecommendationSection.errorRecommendation') });
      }
    },

    async assignTrack(catalogueAtomId: UUID) {
      try {
        await LxpAssignmentService.userTrackAssign({
          catalogueAtomId,
          data: {
            onRecommendation: true,
          },
        });

        if (this.isMandatoryRecommendations) {
          await this.getMandatoryRecommendations();
        } else {
          await this.getFreeRecommendations();
        }
      } catch (err: any) {
        console.error(err);
        this.$di.notify.error({ content: this.$t('RecommendationSection.errorAssign') });
      }
    },
    async removeAssignTrack(assignmentUserId: number) {
      try {
        await LxpAssignmentService.userTrackUnassign(assignmentUserId);

        if (this.isMandatoryRecommendations) {
          await this.getMandatoryRecommendations();
        } else {
          await this.getFreeRecommendations();
        }
      } catch (err: any) {
        console.error(err);
        this.$di.notify.error({ content: this.$t('RecommendationSection.errorUnassign') });
      }
    },
    async goToTrack({ assignmentUserId, catalogueAtomMainId }:
    { assignmentUserId: IAssignment['assignmentUserId'], catalogueAtomMainId: IAssignment['catalogueAtomMainId'] }) {
      try {
        const { playerSessionId } = await LxpAssignmentService.assignmentRun({
          paths: {
            assignmentUserId,
          },
          data: {
            catalogueAtomMainId,
          },
        });

        if (playerSessionId) {
          this.$router.push({
            name: this.Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
            params: {
              assignmentId: String(assignmentUserId),
              trackSessionId: String(playerSessionId),
              playerSessionId: String(playerSessionId),
              atomType: AtomType.TRACK,
            },
          });
        }
      } catch (err: any) {
        this.$di.notify.error({
          content: this.$t('RecommendationSection.errorMandatoryReview'),
        });
      }
    },
  },
});
